export const twoHoursInMs = 7200000

const formatUserEnergy = (
  data,
  // settings = {}
) => {
  const { RfidEvents: e, sessions: s, userInfo: u } = data

  const rows = u?.map((_u, key) => {
    let total = 0

    const subTable = s?.reduce((acc, session) => {
      session?.ChargerSessions?.forEach(cs => {
        const carConnected = new Date(cs?.carConnected)
        const carDisconnected = new Date(cs?.carDisconnected)

        const event = e?.find(event => {
          const timestamp = new Date(event?.Timestamp)

          return (
            event?.UserId === _u?.User?.UserId &&
            event?.ChargerId === session?.ChargerId &&
            ((timestamp - carConnected >= -60000 &&
              timestamp - carConnected <= 60000 &&
              event?.EventType === 'enable') ||
              (timestamp - carDisconnected >= -60000 &&
                timestamp - carDisconnected <= 60000 &&
                event?.EventType === 'disable'))
          )
        })

        if (event != undefined) {
          total += cs?.kiloWattHours

          let indexOfRfId = -1
          let rfId = 'App'

          if (event?.ActivationType === 'rfid') {
            rfId = event?.RfId
          }

          // Get that RFID index from above
          indexOfRfId = acc?.findIndex(tag => tag?.rfId === rfId)

          // If index is is more than -1 we add the KWH to that RFID tag.
          if (indexOfRfId > -1) {
            acc[indexOfRfId].kwh = acc?.[indexOfRfId]?.kwh
              ? acc?.[indexOfRfId]?.kwh + cs?.kiloWattHours
              : cs?.kiloWattHours
          } else {
            // if index is -1 then we add that RFID tag to the array.
            acc.push({
              rfId: rfId,
              kwh: cs?.kiloWattHours,
              key: `child-${acc.length}`,
            })
          }
        }
      })

      return acc
    }, [])

    return {
      username: _u?.User?.Username,
      name: `${_u?.UserInfo?.FirstName ?? ''} ${_u?.UserInfo?.LastName ?? ''}`,
      kwh: total.toFixed(6),
      // price: price,
      key,
      subTable,
    }
  })

  return rows
}

export default formatUserEnergy
